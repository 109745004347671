<template>
  <div class="modalBox">
    <template v-if="loading">
      <div class="flex ju-center al-center" style="height: 200px;">
        <a-spin />
      </div>
    </template>
    <template v-else>
      <div v-for="item in data" :key="item.id" class="itemBox">
      <div class="flex al-center" style="  padding: 10px 20px; border: 1px solid #d9d9d9;">
        <div class="titleBox flex ju-between">
          <div style="width:33%"> 线路名称：{{ item.lineName }}</div>
          <div style="width:30%">承运商数量：{{item.sum}}</div>
          <div style="width:36%" v-if="item.idOptimal === ''"> 当前无最优承运商 <a @click="onEditShow(item)">绑定承运商</a></div>
          <div style="width:36%" v-else> {{ item.carrierName }} <a v-if="item.sum > 1" @click="onEditShow(item)">修改</a></div>
        </div>
        <div class="flex" style="width:42%">
          <div style="width:25%">报价：￥{{item.cost}}</div>
          <div style="width:25%" v-if="item.idOptimal !== ''">时效：{{item.prescription}}</div>
          <div style="width:50%" v-if="item.idOptimal !== ''">备注：{{ item.remark }}</div>
        </div>
        <div>
          <a>
            <span v-if="!item.children" @click="openItem(item)">展开>>></span>
            <span v-else @click="close(item)">收起<<<</span>
          </a>
        </div>
      </div>

      <div v-if="item.children && item.children.length > 0" style="width: 100%;">
        <div v-for="opt in item.children" :key="opt.id" class="optItem flex" >
          <div style="width:50%">
            {{ opt.carrierName }}
          </div>
          <div class="flex" style="width:42%">
            <div style="width:25%"><span style=" opacity: 0;">报价：</span>￥{{opt.cost}}</div>
            <div style="width:25%" ><span style=" opacity: 0;">时效：</span>{{opt.prescription}}</div>
            <div style="width:50%" ><span style=" opacity: 0;">备注：</span>{{ opt.remark }}</div>
          </div>
          <div>
            <a @click="onEditLineShow(opt,item)">
              修改
            </a>
            <a class="m-l1" @click="copyLine(opt,item)">复制线路</a>
          </div>
        </div>
      </div>
    </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { optimalPageDetails } from '@/api/carrier/optimalLine'
const emit = defineEmits(['onEditShow','onEditLineShow','copyLine'])
const props = defineProps({
  data: {
    type: Array,
    default: []
  },
  loading:{
    type:Boolean,
    default:false
  }
})
const openItem = (item) => {
  optimalPageDetails({
    startProvince: item.startProvince,
    startCity: item.startCity,
    startArea: item.startArea,
    endProvince: item.endProvince,
    endCity: item.endCity,
    endArea: item.endArea,
    lineName: item.lineName
  }).then(res => {
    if (res.code !== 10000) return
    item.children = res.data
  })
}
const copyLine = (opt,item) => {
  emit('copyLine',opt,item)
}
const onEditShow = (item) => {
  emit('onEditShow',item)
}
const onEditLineShow = (opt,item) => {
  emit('onEditLineShow',opt,item)
}
const close = (item) => {
  item.children = null
}
defineExpose({ openItem })
</script>

<style lang="less" scoped>
.itemBox {
  margin-top: 10px;

  background: #f5f5f5;
}
.titleBox {
  width: 50%;
 
}

.optItem {
  padding: 10px 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-top: none;
}
</style>